import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import store from "./store/store";
import { auth } from "./firebase/firebase.auth.config";
import HeaderComponent from "./components/landingPage/headerComponent";
import HeaderComponentDashboard from "./components/sidebar/headerComponentDashboard";
import { router } from "./router/router";
import SideBar from "./components/sidebar";
import Loader from "./sharedCOmponent/loader";
import { logoutAction, verifyAuthStatus } from "./action/login/loginAction";
import { fetchUserDetailAction } from "./action/newUser/newUserDetailAction";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  let Header;
  if (location.pathname === "/" || location.pathname === "/about") {
    Header = HeaderComponent;
  } else if (
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/students") ||
    location.pathname.startsWith("/profile")
  ) {
    if (!userUid) {
      dispatch(logoutAction(() => navigate("/login")));
    } else {
      Header = SideBar;
    }
  }

  return (
    <>
      {location.pathname === "/dashboard" ||
      location.pathname === "/profile" ||
      location.pathname === "/students" ? (
        <>
          <HeaderComponentDashboard />
          <div className="side_nav_container ">
            <div className="side_nav p-0 border-right pt-md-4">
              <SideBar />
            </div>
            <div className="side_nav_containt p-md-0 p-2 ms-md-5 ms-md-4 pt-4">
              <Routes>
                {router.routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </div>
          </div>
        </>
      ) : location.pathname === "/login" ? (
        <Routes>
          {router.routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      ) : (
        <>
          <HeaderComponent />
          <Routes>
            {router.routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </>
      )}
    </>
  );
};

const AuthProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { isAuthChecked } = useSelector((state) => state.login);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      dispatch(verifyAuthStatus());
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    if (isAuthChecked && user) {
      dispatch(fetchUserDetailAction());
    }
  }, [isAuthChecked, user, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  if (
    loading &&
    !isAuthChecked &&
    location.pathname !== "/" &&
    location.pathname !== "/about" &&
    location.pathname !== "/login" &&
    !location.pathname.startsWith("/student-form")
  ) {
    return <Loader />;
  }

  return children;
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Layout />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
