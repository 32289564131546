import React from "react";
import TextCardComponents from "../../components/dashboard/textCardComponents";
import TableSubscriptionComponent from "../../components/dashboard/tableSubscriptionComponent";
import PieChartComponent from "../../sharedCOmponent/pieChartComponent";
import BarChart from "../../components/dashboard/activeStudentsGraph";
import {
  getActiveStudentsData,
  getActiveUserData,
  getActiveUserGenderData,
  getTotalSeats,
  getVacantSeats,
} from "../../util/utilsFunction";
import Loader from "../../sharedCOmponent/loader";
import { TablePendingComponent } from "../../components/dashboard/tablePendingComponent";
import { useNavigate } from "react-router-dom";
import ActiveStudentsGraph from "../../components/dashboard/activeStudentsGraph";

const DashboardScreen = ({
  student_details,
  loading,
  error,
  library_details,
  pending_students,
  newUserDetail,
  newOption,
}) => {
  const navigate = useNavigate();
  const activeUserData = getActiveUserData(student_details);
  const shiftSeatData = getTotalSeats(library_details?.shifts);
  const vacantSeats = getVacantSeats(activeUserData.shiftWise, shiftSeatData);
  const maleFemailData = getActiveUserGenderData(student_details);

  function calculateTotalCount(seats) {
    return Object.values(seats).reduce((total, num) => total + num, 0);
  }

  const activeStudentsCount = activeUserData.activeUsers;
  const totalSeats = calculateTotalCount(shiftSeatData);
  const totalVacantSeats = calculateTotalCount(vacantSeats);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : newUserDetail?.data?.approval ? (
        <>
          <h1 className="heading_three my-md-4 p-0">
            {library_details?.libraryName} Dashboard
          </h1>
          <div className="row">
            <div className="col-md-4 col-12 p-2">
              <TextCardComponents
                icon={false}
                show={false}
                desc={false}
                icon_background="#90a955"
                textPara="Total active student"
                number={activeStudentsCount}
                data={activeUserData.shiftWise}
                cutout="50%"
                mainColor={["#2a9d8f", "#e76f51", "#ffafcc"]}
                pie_char_con_class="pie_char_div_con"
              />
            </div>
            <div className="col-md-4 col-12 p-2 ">
              <TextCardComponents
                textPara="Total seats"
                number={totalSeats}
                data={shiftSeatData}
                mainColor={["#023047", "#ffb703", "#b5e48c", "#e63946"]}
                show={false}
                desc={false}
                icon={false}
                cutout="50%"
                pie_char_con_class="pie_char_div_con"
              />
            </div>
            <div className="col-md-4 col-12 p-2 ">
              <TextCardComponents
                textPara="Total vacant seats"
                number={totalVacantSeats}
                data={vacantSeats}
                mainColor={["#d9ed92", "#ffb5a7", "#72ddf7", "#f4d58d"]}
                show={false}
                desc={false}
                icon={false}
                cutout="50%"
                pie_char_con_class="pie_char_div_con"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 p-2 overflow-hidden">
              <TablePendingComponent pending_students={pending_students} />
            </div>

            <div className="col-md-4 col-12 p-2 ">
              <div className="text_card_container_dash h-100">
                <h3 className="cart_harding_text mb-4 pb-2">
                  Active students in past 10 days
                </h3>{" "}
                <ActiveStudentsGraph studentsData={student_details} />
              </div>
            </div>

            <div className="col-md-4 col-12 p-2 piechart_dashboard">
              <div className="text_card_container_dash h-100">
                <h3 className="cart_harding_text mb-4 pb-2">
                  Male v/s Female (All shifts)
                </h3>
                <PieChartComponent
                  data={maleFemailData}
                  mainColor={["#80b918", "#dddf00"]}
                  show={true}
                  desc={true}
                  cutout="75%"
                  pie_char_con_class="pie_char_div_con_male_female"
                />
              </div>
            </div>
            <div className="col-md-8 p-2 overflow-hidden">
              <TableSubscriptionComponent
                student_details={student_details}
                newOption={newOption}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="alert alert-warning text-center mt-5 me-md-5"
            role="alert"
          >
            Please submit your user and library details for verification. You
            will be able to access this screen once the admin approves your
            information.
          </div>
          <button
            className={`button_design_withoutWeight d-flex gap-2 align-items-center  justify-content-center m-auto`}
            onClick={() => navigate("/profile")}
          >
            <span className="material-symbols-outlined icon_dimention">
              arrow_back
            </span>
            <span>Move to Profile Page</span>
          </button>
        </>
      )}
    </>
  );
};

export default DashboardScreen;
