import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import libLogo from "../../asset/image/librify_logo.svg";

const HeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getClassName = (path) => (location.pathname === path ? "active" : "");

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleNavigateJoin = () => {
    navigate("/login");
  };
  return (
    <div className="bg-white border-bottom">
      <div className="container header-container-landing d-flex align-items-center justify-content-between py-2">
        <h4 className="logo-text-header">
          {" "}
          <img className="logo-width" src={libLogo} alt="" />
        </h4>
        <nav className="nav-header-landing d-flex  ">
          <button
            className={getClassName("/")}
            onClick={() => handleNavigation("/")}
          >
            Home
          </button>
          <button
            className={getClassName("/about")}
            onClick={() => handleNavigation("/about")}
          >
            About Us
          </button>
        </nav>
        <button
          className="join-us-btn border-0 bg-white "
          onClick={handleNavigateJoin}
        >
          <span>Join Us</span>
          <span className="material-symbols-outlined icon_dimention">
            north_east
          </span>
        </button>
      </div>
    </div>
  );
};

export default HeaderComponent;
