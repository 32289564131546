export function isActive(sub) {
  const currentDate = new Date();
  let currentDateMinusOne = new Date();
  currentDateMinusOne = currentDateMinusOne.setDate(currentDateMinusOne.getDate() - 1);
  return (
    new Date(sub.endDate) >= currentDateMinusOne &&
    new Date(sub.startDate) <= currentDate
  );
}

export const calculateShiftDuration = (
  startHour,
  startMinute,
  startPeriod,
  endHour,
  endMinute,
  endPeriod
) => {
  const convertTo24HourFormat = (hour, period) => {
    if (period === "PM" && hour !== 12) return hour + 12;
    if (period === "AM" && hour === 12) return 0;
    return hour;
  };

  const startHour24 = convertTo24HourFormat(startHour, startPeriod);
  const endHour24 = convertTo24HourFormat(endHour, endPeriod);

  const startTimeInMinutes = startHour24 * 60 + startMinute;
  const endTimeInMinutes = endHour24 * 60 + endMinute;

  const durationInMinutes = endTimeInMinutes - startTimeInMinutes;
  const durationInHours =
    durationInMinutes > 0
      ? durationInMinutes / 60
      : (1440 + durationInMinutes) / 60;

  return durationInHours.toFixed(1);
};

// duration student subscription calculation
export function calculateDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const options = { day: "numeric", month: "short" };
  const startFormatted = start.toLocaleDateString("en-GB", options);
  const endFormatted = end.toLocaleDateString("en-GB", options);

  return `${startFormatted} - ${endFormatted}`;
}

// Active user finding function
export const getActiveUserData = (students) => {
  if (!Array.isArray(students)) {
    return {};
  }

  const today = new Date().toISOString().split("T")[0]; // Current date in 'yyyy-mm-dd' format
  const Today = new Date();
  const yesterday = new Date(Today);
  yesterday.setDate(Today.getDate() - 1);

  const formattedYesterday = yesterday.toISOString().split("T")[0];
  const activeUsersCountByShift = {};
  let activeUsers = 0;
  students.forEach((student) => {
    const subscriptions = student?.subscription;
    if (subscriptions?.length) {
      // Find the latest subscription and check if it's active in one pass
      let latestSubscription = null;

      for (const sub of subscriptions) {
        if (isActive(sub)) {
          latestSubscription = sub;
          break;
        }
      }

      if (latestSubscription) {
        const startDate = latestSubscription.startDate?.split("T")[0];
        const endDate = latestSubscription.endDate?.split("T")[0];

        // Check if the user is active
        if (today >= startDate && formattedYesterday <= endDate) {
          activeUsers++;
          for (let shiftName of latestSubscription.shift) {
            if (shiftName) {
              activeUsersCountByShift[shiftName] =
                (activeUsersCountByShift[shiftName] || 0) + 1;
            }
          }
        }
      }
    }
  });
  const res = { activeUsers: activeUsers, shiftWise: activeUsersCountByShift };
  return res;
};

// total seats function
export const getTotalSeats = (libraryDetails) => {
  const totalSeats = {};
  const numEg = ["One", "Two", "Three", "Four"];

  libraryDetails?.forEach((shiftWise, index) => {
    totalSeats[`Shift ${numEg[index]}`] = parseInt(shiftWise.totalSeats);
  });

  return totalSeats;
};

// Vacant Seat Calculate
export const getVacantSeats = (activeUserData, shiftSeatData) => {
  const vacantSeats = {};

  for (const shift in shiftSeatData) {
    if (shiftSeatData.hasOwnProperty(shift)) {
      const totalSeats = shiftSeatData[shift];
      const activeUsers = activeUserData[shift] || 0;
      const vacant = totalSeats - activeUsers;

      vacantSeats[shift] = vacant;
    }
  }

  return vacantSeats;
};

// past 4 months student calculation
export function getActiveStudentsData(students) {
  if (!Array.isArray(students)) {
    return [];
  }

  function isActiveInMonth(subscription, month, year) {
    const startDate = new Date(subscription.startDate);
    const endDate = new Date(subscription.endDate);

    const monthStart = new Date(year, month, 1);
    const monthEnd = new Date(year, month + 1, 0);

    const startCheck = startDate <= monthEnd;
    const endCheck = endDate >= monthStart;

    // Calculate active days in month
    const activeStartDate = new Date(Math.max(startDate, monthStart));
    const activeEndDate = new Date(Math.min(endDate, monthEnd));
    const activeDaysInMonth =
      (activeEndDate - activeStartDate) / (1000 * 60 * 60 * 24) + 1;

    // Check if the subscription is active for more than 15 days
    const isActive =
      startCheck &&
      endCheck &&
      (activeDaysInMonth > 15 ||
        endDate.getTime() - startDate.getTime() <= 14 * 24 * 60 * 60 * 1000);

    return isActive;
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const chartData = [];
  for (let i = 3; i >= 0; i--) {
    const month = new Date(currentYear, currentMonth - i, 1);
    const monthLabel = month.toLocaleString("default", { month: "long" });
    const monthIndex = month.getMonth();
    const monthYear = month.getFullYear();

    const activeStudents = students.filter((student) =>
      student.subscription?.some((subscription) =>
        isActiveInMonth(subscription, monthIndex, monthYear)
      )
    );

    chartData.push({
      label: monthLabel,
      value: activeStudents.length,
      name: activeStudents.map((student) => student.name),
    });
  }

  return chartData;
}

// 3 and 7 days students data
export const getUpcomingExpireData = (days, data) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate);
  targetDate.setDate(currentDate.getDate() + days);

  const filteredData = [];

  data?.forEach((item) => {
    if (item?.subscription?.length) {
      let lastSubscription = item.subscription[item.subscription.length - 1];
      for (const sub of item?.subscription) {
        if (isActive(sub)) {
          lastSubscription = sub;
          break;
        }
      }
      let isLast =
        lastSubscription === item.subscription[item.subscription.length - 1];

      if (lastSubscription) {
        let endDate = new Date(lastSubscription.endDate);
        endDate = endDate.setDate(endDate.getDate() + 1);
        if (
          // !isNaN(endDate.getTime()) &&
          isLast &&
          endDate >= currentDate &&
          endDate <= targetDate
        ) {
          filteredData.push(item);
        }
      }
    }
  });

  return filteredData;
};

// get gender details all
export function getGenderCount(data) {
  const genderCount = {};

  data.forEach((student) => {
    const gender = student.gender;
    if (genderCount[gender]) {
      genderCount[gender] += 1;
    } else {
      genderCount[gender] = 1;
    }
  });

  return genderCount;
}

// active user gender detail
export const getActiveUserGenderData = (students) => {
  // Validate if students is an array
  if (!Array.isArray(students)) {
    return {};
  }

  const today = new Date().toISOString().split("T")[0];
  const activeUsersCountByGender = {};

  const Today = new Date();
  const yesterday = new Date(Today);
  yesterday.setDate(Today.getDate() - 1);

  const formattedYesterday = yesterday.toISOString().split("T")[0];

  for (const student of students) {
    if (student?.subscription?.length) {
      let latestSubscription = student.subscription[0];

      for (const sub of student.subscription) {
        if (isActive(sub)) {
          latestSubscription = sub;
          break;
        }
      }

      const isActivebool =
        today >= latestSubscription.startDate.split("T")[0] &&
        formattedYesterday <= latestSubscription.endDate.split("T")[0];

      if (isActivebool) {
        const gender = student.gender;
        if (activeUsersCountByGender[gender]) {
          activeUsersCountByGender[gender]++;
        } else {
          activeUsersCountByGender[gender] = 1;
        }
      }
    }
  }

  return activeUsersCountByGender;
};

// get base url
export function getBaseURL(url) {
  try {
    const { origin } = new URL(url);
    return origin + "/";
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

// date formater
export const formatDate = (dateStr) => {
  const options = { day: "numeric", month: "long" };
  return new Date(dateStr).toLocaleDateString("en-GB", options);
};

// generate member id in forms
// Auto generate member id
export const generateMemberID = () => {
  const date = new Date();
  const month = date.toLocaleString("en-us", { month: "short" }).toUpperCase(); // Month e.g. 'SEP'
  const randomChar = Math.random().toString(36).charAt(2).toUpperCase(); // Random alphanumeric (A-Z, 0-9)
  const year = date.getFullYear().toString().slice(-2); // Last 2 digits of the year
  const day = date.getDate().toString().padStart(2, "0"); // Day of the month (01-31)
  const hour = date.getHours().toString().padStart(2, "0"); // Hour (00-23)
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes (00-59)
  const seconds = date.getSeconds().toString().padStart(2, "0"); // Seconds (00-59)

  return `${month}${randomChar}${year}${day}${hour}${minutes}${seconds}`;
};

// student detail page shift wise filter option converter
export function convertShiftsToOptions(data) {
  const shiftsCategory = data?.find((item) => item.category === "Shifts");

  if (!shiftsCategory || !shiftsCategory?.options) return [];

  const options = shiftsCategory.options.map((shift) => {
    const [label] = shift.split(" (");
    return { value: label, label: label };
  });

  return [{ value: "All", label: "All Shifts" }, ...options];
}
