import React, { useState, useEffect } from "react";
import TableStudentComponent from "../../components/studentsDetails/tableStudentComponent";
import ModalStudentDetailComponent from "../../components/studentsDetails/modalStudentDetailComponent";
import { agGridFilterData } from "../../util/agGridFilterData";
import { FormGroup, FormLabel } from "react-bootstrap";
import ReactSelect from "react-select";
import Select from "react-select";
import { customStyles } from "../../util/utils";
import Loader from "../../sharedCOmponent/loader";
import { useNavigate } from "react-router-dom";
import ModalEditStudentDetailComponent from "../../components/studentsDetails/ModalEditStudentDetailComponent";
import { convertShiftsToOptions } from "../../util/utilsFunction";
import { exportToExcel } from "../../util/studentDataDownloadExcel";

const StudentScreen = ({ student_details, loading, error, newUserDetail }) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedShift, setSelectedShift] = useState("All");
  const [activeStatus, setActiveStatus] = useState("All");
  const [modalDataEdit, setModalDataEdit] = useState(null);

  const shiftOptions = convertShiftsToOptions(newUserDetail?.data?.options);
  useEffect(() => {
    if (student_details) {
      const filtered = agGridFilterData(
        student_details,
        searchTerm,
        selectedShift,
        activeStatus
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, selectedShift, activeStatus, student_details]);

  const handleView = (student) => {
    setModalData(student);
  };

  const handleEdit = (student) => {
    setModalDataEdit(student);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : newUserDetail.data.approval ? (
        <div className="me-2 ms-2 ms-md-0 me-md-5 ">
          <div className="d-flex align-items-center justify-content-between gap-md-0 gap-3 mb-3 mt-md-4">
            <h1 className="heading_three ">Students Details</h1>{" "}
            <div className=" text-md-end d-flex flex-column flex-md-row   align-items-end  gap-2">
              <button
                className="border-0 bg-transparent d-flex align-items-center gap-1"
                onClick={() => exportToExcel(student_details)}
              >
                <span className="material-symbols-outlined icon_dimention">
                  download
                </span>
                <p className="table_row_cell text-nowrap mb-0">Download</p>
              </button>
            </div>
          </div>
          <div className="row align-items-center justify-content-between gap-3 gap-md-2">
            <div className="search-bar col-12 col-md-6 align-items-center">
              <FormLabel className="label_text ">Search students</FormLabel>
              <input
                type="text"
                className="search_input col-12"
                placeholder="Search by name, member ID, or mobile..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="filters col-12 col-md-4 d-flex align-items-center gap-2">
              <FormGroup className="w-50">
                <FormLabel className="label_text">
                  Shift wise students
                </FormLabel>
                <Select
                  options={shiftOptions}
                  value={
                    selectedShift
                      ? [{ value: selectedShift, label: selectedShift }]
                      : { value: "All", label: "All Shifts" }
                  }
                  className="formBodyControlModal"
                  placeholder="Select Shift"
                  isSearchable
                  onChange={(option) => setSelectedShift(option.value)}
                  styles={customStyles}
                />
              </FormGroup>

              <FormGroup className="w-50">
                <FormLabel className="label_text">Active status</FormLabel>
                <ReactSelect
                  options={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  value={
                    activeStatus
                      ? [{ value: activeStatus, label: activeStatus }]
                      : { value: "All", label: "All" }
                  }
                  className="formBodyControlModal"
                  placeholder="Select Status"
                  isSearchable
                  onChange={(option) => setActiveStatus(option.value)}
                  styles={customStyles}
                />
              </FormGroup>
            </div>
          </div>
          <TableStudentComponent
            data={filteredData}
            onView={handleView}
            onEdit={handleEdit}
          />
          {modalData && (
            <ModalStudentDetailComponent
              student={modalData}
              onClose={() => setModalData(null)}
            />
          )}
          {modalDataEdit && (
            <ModalEditStudentDetailComponent
              student={modalDataEdit}
              onClose={() => setModalDataEdit(null)}
              dropdownData={newUserDetail.data.options}
            />
          )}
        </div>
      ) : (
        <>
          <div
            className="alert alert-warning text-center mt-5 me-md-5"
            role="alert"
          >
            Please submit your user and library details for verification. You
            will be able to access this screen once the admin approves your
            information.
          </div>
          <button
            className={`button_design_withoutWeight d-flex gap-2 align-items-center  justify-content-center m-auto`}
            onClick={() => navigate("/profile")}
          >
            <span className="material-symbols-outlined icon_dimention">
              arrow_back
            </span>
            <span>Move to Profile Page</span>
          </button>
        </>
      )}
    </>
  );
};

export default StudentScreen;
