// src\components\login\numberInputComponent.js
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInputComponent = ({
  phoneNumber,
  setPhoneNumber,
  onSendOtp,
  loading,
  otpError,
}) => {
  return (
    <div className=" opt-container  m-auto d-flex flex-column justify-content-center h-100 gap-3 gap-md-4">
      <h3 className="heading_three mb-0 login_heading">Login with phone no.</h3>
      <div>
        <label htmlFor="phoneNumber" className="label_text ">
          Enter your number
        </label>
        <PhoneInput
          className="phone_input w-100"
          country={"in"}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </div>
      <button
        onClick={onSendOtp}
        className=" btn border-0 outline-0 pri_bg text-white button_design sent_otp_btn w-100"
        disabled={loading}
      >
        {loading ? (
          <div className="ms-3">
            <div
              className="spinner-border text-light spinner_class"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <span>Send OTP</span>
        )}
      </button>
      {otpError && <p className="text-red-500 text-center">{otpError}</p>}
    </div>
  );
};

export default PhoneNumberInputComponent;
