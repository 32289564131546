import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Select from "react-select";
import { customStyles } from "../../util/utils";
import { useDispatch } from "react-redux";
import { addSubscriptionDetailAction } from "../../action/newSubscription/newSubscriptionDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import moment from "moment";

const ReNewSubModalComponent = ({
  show,
  setShowModalRenewSub,
  data,
  newOption,
}) => {
  const dispatch = useDispatch();
  const [usePreviousData, setUsePreviousData] = useState(false);
  const lastSubscription =
    data?.subscription?.[data?.subscription?.length - 1] || {};

  const getOptions = (category) => {
    const options = Array.isArray(newOption)
      ? newOption.find((item) => item?.category === category)?.options || []
      : [];
    return options.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const shiftOptions = getOptions("Shifts");

  const initialValues = {
    startDate: "",
    endDate: "",
    seatNo: usePreviousData ? lastSubscription?.seatNo : "",
    shift: usePreviousData ? lastSubscription?.shift || [] : [],
    fee: usePreviousData ? lastSubscription?.fee : "",
    library: lastSubscription?.library,
    member: lastSubscription?.member,
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    seatNo: Yup.number().required("Required"),
    shift: Yup.array().required("Required"),
    fee: Yup.string().required("Required"),
  });

  const successCb = () => {
    dispatch(fetchUserDetailAction());
  };

  const errorCb = (error) => {
    console.error(error);
  };

  const handleSubmit = (values, { resetForm }) => {
    const formattedValues = {
      ...values,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
    };
    dispatch(
      addSubscriptionDetailAction(formattedValues, data?.id, successCb, errorCb)
    );
    resetForm();
    setShowModalRenewSub(false);
  };

  const handleCheckboxChange = (e, setFieldValue) => {
    const checked = e.target.checked;
    setUsePreviousData(checked);

    if (checked) {
      setFieldValue("seatNo", lastSubscription?.seatNo || "");
      setFieldValue("shift", lastSubscription?.shift || []);
      setFieldValue("fee", lastSubscription?.fee || "");
    } else {
      setFieldValue("seatNo", "");
      setFieldValue("shift", []);
      setFieldValue("fee", "");
    }
  };

  const handleContinue = (setFieldValue) => {
    if (lastSubscription.endDate) {
      const newStartDate = new Date(lastSubscription.endDate);
      newStartDate.setDate(newStartDate.getDate() + 1);

      const newEndDate = new Date(newStartDate);
      newEndDate.setMonth(newEndDate.getMonth() + 1);

      setFieldValue("startDate", newStartDate.toISOString());
      setFieldValue("endDate", newEndDate.toISOString());
      setFieldValue("seatNo", lastSubscription?.seatNo || "");
      setFieldValue("shift", lastSubscription?.shift || []);
      setFieldValue("fee", lastSubscription?.fee || "");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShowModalRenewSub(false);
      }}
      backdrop="static"
      keyboard={false}
      className="renew-subscription-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="heading_four ">
          {`Renew Subscription - ${data?.name || ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, resetForm }) => (
            <Form className="d-flex flex-column gap-3">
              <FormGroup controlId="dateRange">
                <FormLabel className="label_text">
                  Select duration of subscription
                </FormLabel>
                <div className="d-flex flex-md-row flex-column gap-3">
                  <DatePicker
                    selected={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    onChange={(date) => {
                      setFieldValue("startDate", date.toISOString());
                    }}
                    selectsStart
                    startDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    endDate={values.endDate ? new Date(values.endDate) : null}
                    minDate={
                      lastSubscription.endDate
                        ? new Date(
                            new Date(lastSubscription.endDate).setDate(
                              new Date(lastSubscription.endDate).getDate() + 1
                            )
                          )
                        : null
                    }
                    dateFormat="dd/MM/yyyy" // Updated date format for user display
                    placeholderText="Start Date"
                    className={`form-control input_feild ${
                      errors.startDate && touched.startDate ? "is-invalid" : ""
                    }`}
                    value={
                      values.startDate
                        ? moment(values.startDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                  <DatePicker
                    selected={values.endDate ? new Date(values.endDate) : null}
                    onChange={(date) => {
                      setFieldValue("endDate", date.toISOString());
                    }}
                    selectsEnd
                    startDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    endDate={values.endDate ? new Date(values.endDate) : null}
                    minDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    dateFormat="dd/MM/yyyy" // Updated date format for user display
                    placeholderText="End Date"
                    className={`form-control input_feild ${
                      errors.endDate && touched.endDate ? "is-invalid" : ""
                    }`}
                    value={
                      values.endDate
                        ? moment(values.endDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Seat No.</FormLabel>
                <Field
                  name="seatNo"
                  type="text"
                  className={`form-control input_feild ${
                    errors.seatNo && touched.seatNo ? "is-invalid" : ""
                  }`}
                />
                {errors.seatNo && touched.seatNo && (
                  <div className="text-danger">{errors.seatNo}</div>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Shift</FormLabel>
                <Select
                  options={shiftOptions}
                  value={
                    values.shift
                      ? shiftOptions.filter((option) =>
                          values.shift.includes(option.value.split(" (")[0])
                        )
                      : []
                  }
                  isMulti={true}
                  className="formBodyControlModal"
                  placeholder="Select Shift"
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "shift",
                      selectedOptions.map(
                        (option) => option.value.split(" (")[0]
                      )
                    )
                  }
                  styles={customStyles}
                />

                {errors.shift && touched.shift && (
                  <div className="text-danger">{errors.shift}</div>
                )}
              </FormGroup>

              <FormGroup controlId="fee">
                <FormLabel className="label_text">Fee</FormLabel>
                <Field
                  name="fee"
                  type="text"
                  className={`form-control input_feild ${
                    errors.fee && touched.fee ? "is-invalid" : ""
                  }`}
                />
                {errors.fee && touched.fee && (
                  <div className="text-danger">{errors.fee}</div>
                )}
              </FormGroup>

              <div className="d-flex gap-3 align-items-center justify-content-between my-1 py-3 border-top border-bottom">
                <FormGroup controlId="usePreviousData ">
                  <FormCheck
                    type="checkbox"
                    label="Same as previous data"
                    checked={usePreviousData}
                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                  />
                </FormGroup>
                <Button
                  className="pri_radious btn-success border-none border-0 mt-0 me-2 select_duration_btn"
                  onClick={() => handleContinue(setFieldValue)}
                >
                  Continue
                </Button>
              </div>

              <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
                <Button
                  className="button_design w-50"
                  variant="outline"
                  onClick={() => {
                    setShowModalRenewSub(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="pri_bg button_design w-50 shadow-none"
                  variant="secondary"
                  type="submit"
                >
                  Renew Seat
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ReNewSubModalComponent;
