import React, { useState } from "react";
import { calculateDuration } from "../../util/utilsFunction";
import { useDispatch } from "react-redux";
import {
  approveMemberDetailAction,
  deleteMemberDetailAction,
} from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import toast from "react-hot-toast";
import ModalStudentDetailComponent from "../studentsDetails/modalStudentDetailComponent";

export const TablePendingComponent = ({ pending_students }) => {
  const user_uid = sessionStorage.getItem("user_uid");
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  const handleApprove = (value) => {
    const successCb = () => {
      toast.success("Approved successfully!");
      dispatch(fetchUserDetailAction());
    };
    const errorCb = (error) => {
      toast.error(error);
    };
    dispatch(approveMemberDetailAction(value, user_uid, successCb, errorCb));
  };

  const handleDeny = (value) => {
    const successCb = () => {
      toast.success("Rejected successfully!");
      dispatch(fetchUserDetailAction());
    };
    const errorCb = (error) => {
      toast.error(error);
    };
    dispatch(deleteMemberDetailAction(value, user_uid, successCb, errorCb));
  };

  const handleView = (student) => {
    setModalData(student);
  };

  return (
    <div className="text_card_container_dash table_dashboard">
      <div className="d-flex align-items-center justify-content-between mb-3 pb-1">
        <p className="cart_harding_text">
          New student request{" "}
          {pending_students ? `(${pending_students.length})` : "(0)"}
        </p>
      </div>
      {pending_students && pending_students.length > 0 ? (
        <div className="table-responsive overflow-y-scroll overflow-x-scroll">
          <table className="table custom-table-dashboard">
            <thead>
              <tr>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Student ID
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Name
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Fee Duration
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Fee
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Seat No.
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pending_students?.map((row) => (
                <tr key={row.memberID}>
                  <td className="table_row_cell text-nowrap">{row.memberID}</td>
                  <td className="table_row_cell text-nowrap">{row.name}</td>
                  <td className="table_row_cell text-nowrap">
                    {calculateDuration(
                      row.subscription?.[0]?.startDate,
                      row.subscription?.[0]?.endDate
                    )}
                  </td>
                  <td className="table_row_cell text-nowrap">
                    {row.subscription?.[0]?.fee}
                  </td>
                  <td className="table_row_cell text-nowrap">
                    {row.subscription?.[0]?.seatNo}
                  </td>
                  <td className="d-flex gap-1 table_row_cell text-nowrap">
                    <button
                      className="pri_radious border-none border-0 mt-0 me-2 select_duration_btn bg-white"
                      onClick={() => handleView(row)}
                    >
                      View
                    </button>
                    <button
                      className="pri_radious pri_bg border-none border-0 mt-0 me-2 text-white select_duration_btn"
                      onClick={() => handleApprove(row.id)}
                    >
                      Approve
                    </button>
                    <button
                      className="pri_radious border-none border-0 mt-0 me-2 select_duration_btn"
                      onClick={() => handleDeny(row.id)}
                    >
                      Deny
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center my-3">No data available</p>
      )}
      {modalData && (
        <ModalStudentDetailComponent
          student={modalData}
          onClose={() => setModalData(null)}
          modalDelete="detail-view"
          handleApprove={handleApprove}
        />
      )}
    </div>
  );
};
