import React from "react";
import { Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import ModalComponent from "./modalComponent";
import LibraryFacilities from "./libraryFacilities";
import { useDispatch } from "react-redux";
import { addLibraryDetailAction } from "../../action/newUser/newLibraryDetailAction";

const LibraryDetailForm = ({
  show,
  handleClose,
  selectedLibrary,
  successCb,
  errorCb,
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    libraryName: Yup.string()
      .required("Library Name is required")
      .min(2, "Must be at least 2 characters"),
    libraryContact: Yup.string()
      .required("Library Contact is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    libraryEmail: Yup.string()
      .email("Invalid email address")
      .required("Library Email is required"),
    libraryDescription: Yup.string(),
    libraryLocation: Yup.string().required("Location is required"),
    pinCode: Yup.string()
      .required("Pin Code is required")
      .matches(/^\d{6}$/, "Must be a valid 6-digit Pin Code"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    libraryFacilities: Yup.array().min(1, "Select at least one facility"),
    libraryFee: Yup.number().min(0, "Library Fee cannot be negative"),

    shifts: Yup.array().of(
      Yup.object().shape({
        startHour: Yup.number().required("Start hour is required"),
        startMinute: Yup.number().required("Start minute is required"),
        startPeriod: Yup.string().required("Start period is required"),
        endHour: Yup.number().required("End hour is required"),
        endMinute: Yup.number().required("End minute is required"),
        endPeriod: Yup.string().required("End period is required"),
        totalSeats: Yup.number()
          .required("Seats important")
          .min(1, "At least 1 seat is required"),
        fee: Yup.number().required("Fee required"),
      })
    ),
  });

  const handleCreate = (values, actions) => {
    handleClose();
    dispatch(addLibraryDetailAction(values, successCb, errorCb));
  };

  const initialValues = {
    libraryName: selectedLibrary?.libraryName || "",
    libraryContact: selectedLibrary?.libraryContact || "",
    libraryEmail: selectedLibrary?.libraryEmail || "",
    libraryDescription: selectedLibrary?.libraryDescription || "",
    libraryLocation: selectedLibrary?.libraryLocation || "",
    pinCode: selectedLibrary?.pinCode || "",
    district: selectedLibrary?.district || "",
    state: selectedLibrary?.state || "",
    libraryFacilities: selectedLibrary?.libraryFacilities || [],
    shifts: selectedLibrary?.shifts || [
      {
        startHour: 12,
        startMinute: 0,
        startPeriod: "AM",
        endHour: 11,
        endMinute: 45,
        endPeriod: "PM",
        totalSeats: "",
        fee: "",
      },
    ],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <ModalComponent
          show={show}
          handleClose={handleClose}
          title={selectedLibrary ? "Update Library Details" : "Add New Library"}
          body={
            <ModalContent
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              initialFacilities={values.libraryFacilities}
              shifts={values.shifts}
              onAddShift={() => {
                setFieldValue("shifts", [
                  ...values.shifts,
                  {
                    startHour: 1,
                    startMinute: 0,
                    startPeriod: "AM",
                    endHour: 1,
                    endMinute: 0,
                    endPeriod: "AM",
                    totalSeats: "",
                  },
                ]);
              }}
              onRemoveShift={(index) => {
                const updatedShifts = values.shifts.filter(
                  (_, i) => i !== index
                );
                setFieldValue("shifts", updatedShifts);
              }}
            />
          }
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  );
};

const ModalContent = ({
  setFieldValue,
  initialFacilities,
  shifts,
  onAddShift,
  onRemoveShift,
}) => {
  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = [0, 15, 30, 45];
  const periods = ["AM", "PM"];

  return (
    <FormikForm className="row gap-3">
      <Form.Label className="label_text_large mb-0">
        Add Basic Details
      </Form.Label>
      <Form.Group controlId="formLibraryName" className="col-12">
        <Form.Label className="label_text">Library Name</Form.Label>
        <Field
          name="libraryName"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter library name"
        />
        <ErrorMessage
          name="libraryName"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formLibraryContact" className="col-12">
        <Form.Label className="label_text">Library Contact No.</Form.Label>
        <Field
          name="libraryContact"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter contact number"
        />
        <ErrorMessage
          name="libraryContact"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formLibraryEmail" className="col-12">
        <Form.Label className="label_text">Library Email ID</Form.Label>
        <Field
          name="libraryEmail"
          type="email"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter email ID"
        />
        <ErrorMessage
          name="libraryEmail"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formLibraryDescription" className="col-12">
        <Form.Label className="label_text">
          Library Description <span className="pri_text">(optional)</span>
        </Form.Label>
        <Field
          name="libraryDescription"
          as="textarea"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter description"
        />
        <ErrorMessage
          name="libraryDescription"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formlibraryLocation" className="col-12">
        <Form.Label className="label_text">Library location</Form.Label>
        <Field
          name="libraryLocation"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter location"
        />
        <ErrorMessage
          name="libraryLocation"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <div className="d-flex p-0">
        <Form.Group controlId="formPinCode" className="col-6">
          <Form.Label className="label_text">Pin Code</Form.Label>
          <Field
            name="pinCode"
            type="number"
            className="formBodyControlModal form-control input_feild"
            placeholder="Enter pin code"
          />
          <ErrorMessage
            name="pinCode"
            component="div"
            className="text-danger"
          />
        </Form.Group>

        <Form.Group controlId="formDistrict" className="col-6">
          <Form.Label className="label_text">District</Form.Label>
          <Field
            name="district"
            type="text"
            className="formBodyControlModal form-control input_feild"
            placeholder="Enter district"
          />
          <ErrorMessage
            name="district"
            component="div"
            className="text-danger"
          />
        </Form.Group>
      </div>

      <Form.Group controlId="formState" className="col-12">
        <Form.Label className="label_text">State</Form.Label>
        <Field
          name="state"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter state"
        />
        <ErrorMessage name="state" component="div" className="text-danger" />
      </Form.Group>

      <Form.Label className="label_text_large mb-0">
        Library Facilities
      </Form.Label>
      <Form.Group controlId="formLibraryFacilities" className="col-12 ">
        <Form.Label className="label_text mb-2">Library Facilities</Form.Label>
        <LibraryFacilities
          name="libraryFacilities"
          setFieldValue={setFieldValue}
          initialFacilities={initialFacilities}
        />
        <ErrorMessage
          name="libraryFacilities"
          component="div"
          className="text-danger"
        />
      </Form.Group>
      <Form.Group controlId="formShifts " className="col-12 border-top ">
        <Form.Label className="label_text_large pb-2 mt-3">
          Add Your Shifts <br />
          <span className="library-fee-notification">
            Note:- There is a fee section: Library Fee per Month (by Selected
            Shift)
          </span>
        </Form.Label>
        {shifts.map((shift, index) => (
          <div key={index} className="shift-row mb-3 ">
            <div className="d-flex justify-content-between align-items-center shift-name-top">
              <Form.Label className="label_text mb-0 shift-heading-lib">
                Add shift-{index + 1} details
              </Form.Label>
              <div className="col-md-1 col-2">
                {index > 0 && (
                  <button
                    type="button"
                    className=" btn p-0 border-0 outline-0 "
                    onClick={() => onRemoveShift(index)}
                  >
                    <span className="material-symbols-outlined icon_dimention">
                      delete
                    </span>
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 form-shift-lib">
              <div className="col-5 col-md-5 mb-0 p-0">
                <Form.Label className="label_text">Start Time</Form.Label>
                <div className="d-flex">
                  <Field
                    as="select"
                    name={`shifts[${index}].startHour`}
                    className="form-control me-2 "
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Field>
                  <Field
                    as="select"
                    name={`shifts[${index}].startMinute`}
                    className="form-control me-2"
                  >
                    {minutes.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute.toString().padStart(2, "0")}
                      </option>
                    ))}
                  </Field>
                  <Field
                    as="select"
                    name={`shifts[${index}].startPeriod`}
                    className="form-control"
                  >
                    {periods.map((period) => (
                      <option key={period} value={period}>
                        {period}
                      </option>
                    ))}
                  </Field>
                </div>
                <ErrorMessage
                  name={`shifts[${index}].startHour`}
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name={`shifts[${index}].startMinute`}
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name={`shifts[${index}].startPeriod`}
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-5 col-md-5 mb-0 p-0">
                <Form.Label className="label_text">End Time</Form.Label>
                <div className="d-flex">
                  <Field
                    as="select"
                    name={`shifts[${index}].endHour`}
                    className="form-control me-2"
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Field>
                  <Field
                    as="select"
                    name={`shifts[${index}].endMinute`}
                    className="form-control me-2"
                  >
                    {minutes.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute.toString().padStart(2, "0")}
                      </option>
                    ))}
                  </Field>
                  <Field
                    as="select"
                    name={`shifts[${index}].endPeriod`}
                    className="form-control"
                  >
                    {periods.map((period) => (
                      <option key={period} value={period}>
                        {period}
                      </option>
                    ))}
                  </Field>
                </div>
                <ErrorMessage
                  name={`shifts[${index}].endHour`}
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name={`shifts[${index}].endMinute`}
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name={`shifts[${index}].endPeriod`}
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-4 col-md-5 mb-2 p-0">
                <Form.Label className="label_text">Total Seats</Form.Label>
                <Field
                  name={`shifts[${index}].totalSeats`}
                  type="number"
                  className="form-control form-input-lib"
                  placeholder="Enter total seats"
                />
                <ErrorMessage
                  name={`shifts[${index}].totalSeats`}
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-4 col-md-5 mb-2 p-0">
                <Form.Label className="label_text">Shift Fee</Form.Label>
                <Field
                  name={`shifts[${index}].fee`}
                  type="number"
                  className="form-control form-input-lib"
                  placeholder="Enter fee"
                />
                <ErrorMessage
                  name={`shifts[${index}].fee`}
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="button_design float-right"
          onClick={onAddShift}
        >
          + Add Shift
        </button>
      </Form.Group>
    </FormikForm>
  );
};

export default LibraryDetailForm;
