import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { addMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import { generateMemberID } from "../../util/utilsFunction";

const CustomForm = ({ onClose, dropdownData }) => {
  const userUid = sessionStorage.getItem("user_uid");
  const dispatch = useDispatch();
  const [sameAsMobile, setSameAsMobile] = useState(false);

  // Dropdown options for react-select
  const getOptions = (category) =>
    dropdownData
      .find((item) => item.category === category)
      ?.options.map((option) => ({
        label: option,
        value: option,
      })) || [];

  const shiftOptions = getOptions("Shifts");
  const goalOptions = getOptions("Goals");

  // Custom style for react-select dropdown
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "48px",
      height: "48px",
      borderRadius: "8px",
      height: state.hasValue ? "auto" : "48px",
      borderColor: "#ced4da",
      boxShadow: "none",
      padding: "0px 8px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "auto",
    }),
  };

  const animatedComponents = makeAnimated();

  // Formik initial values
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    gender: "",
    subscription: [
      {
        seatNo: "",
        shift: [],
        startDate: null,
        endDate: null,
        fee: "",
      },
    ],
    address: "",
    goal: "",
    memberID: "",
    approved: true,
    aadhar_number: "",
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),

    mobile: Yup.string()
      .matches(/^\d+$/, "Mobile number must be numeric")
      .length(10, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),

    whatsapp: Yup.string()
      .matches(/^\d+$/, "Mobile number must be numeric")
      .length(10, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    gender: Yup.string().required("Gender is required"),

    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.number().required("Seat number is required"),
        shift: Yup.array()
          .min(1, "At least one shift is required")
          .required("Shift is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after start date"),
        fee: Yup.number().required("Fee is required"),
      })
    ),

    address: Yup.string().required("Address is required"),
    goal: Yup.string(),
    memberID: Yup.string().required("Member ID is required"),
  });

  // Convert the date to ISO format string for backend submission
  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const successCb = (data) => {
    dispatch(fetchUserDetailAction());
  };
  const errorCb = (error) => {
    console.log(error);
  };

  // Form submit handler
  const handleFormSubmit = (values) => {
    const formattedValues = {
      ...values,
      subscription: values.subscription.map((sub) => ({
        ...sub,
        startDate: formatDateToISO(sub.startDate),
        endDate: formatDateToISO(sub.endDate),
      })),
    };
    dispatch(
      addMemberDetailAction(formattedValues, successCb, errorCb, userUid)
    );
    onClose();
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form className="row gap-3">
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Name
            </BootstrapForm.Label>
            <Field
              name="name"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your name"
            />
            {errors.name && touched.name ? (
              <div className="text-danger">{errors.name}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Mobile
            </BootstrapForm.Label>
            <Field
              name="mobile"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your mobile number"
              onChange={(e) => {
                setFieldValue("mobile", e.target.value);
                if (sameAsMobile) setFieldValue("whatsapp", e.target.value);
              }}
            />
            {errors.mobile && touched.mobile ? (
              <div className="text-danger">{errors.mobile}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              WhatsApp
            </BootstrapForm.Label>
            <Field
              name="whatsapp"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your WhatsApp number"
            />
            <BootstrapForm.Check
              type="checkbox"
              label="Same as mobile"
              onChange={() => {
                setSameAsMobile(!sameAsMobile);
                if (!sameAsMobile) {
                  setFieldValue("whatsapp", values.mobile);
                }
              }}
              checked={sameAsMobile}
            />
            {errors.whatsapp && touched.whatsapp ? (
              <div className="text-danger">{errors.whatsapp}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Aadhar number (optional)
            </BootstrapForm.Label>
            <Field
              name="aadhar_number"
              type="number"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter aadhar"
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Email
            </BootstrapForm.Label>
            <Field
              name="email"
              type="email"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your email"
            />
            {errors.email && touched.email ? (
              <div className="text-danger">{errors.email}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Gender
            </BootstrapForm.Label>
            <div className="d-flex gap-4">
              <label className="mb-0 d-flex gap-2">
                <Field type="radio" name="gender" value="Male" />
                Male
              </label>
              <label className="mb-0 d-flex gap-2">
                <Field type="radio" name="gender" value="Female" />
                Female
              </label>
            </div>
            {errors.gender && touched.gender ? (
              <div className="text-danger">{errors.gender}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription start date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].startDate}
              onChange={(date) =>
                setFieldValue("subscription[0].startDate", date)
              }
              selectsStart
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.startDate &&
                touched.subscription?.[0]?.startDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription end date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].endDate}
              onChange={(date) =>
                setFieldValue("subscription[0].endDate", date)
              }
              selectsEnd
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              minDate={values.subscription[0].startDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.endDate &&
                touched.subscription?.[0]?.endDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Shift
            </BootstrapForm.Label>
            <Select
              options={shiftOptions}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder="Select Shift (Multiple Select)"
              isMulti
              onChange={(selectedOptions) => {
                const formattedOptions = selectedOptions.map((option) => {
                  const formattedValue = option.value.split("(")[0].trim();
                  return formattedValue;
                });
                setFieldValue("subscription[0].shift", formattedOptions);
              }}
              styles={customStyles}
            />
            {errors.subscription?.[0]?.shift &&
            touched.subscription?.[0]?.shift ? (
              <div className="text-danger">{errors.subscription[0].shift}</div>
            ) : null}
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Seat No.
            </BootstrapForm.Label>
            <Field
              name="subscription[0].seatNo"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter Seat No."
            />
            {errors.subscription?.[0]?.seatNo &&
            touched.subscription?.[0]?.seatNo ? (
              <div className="text-danger">{errors.subscription[0].seatNo}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Fee
            </BootstrapForm.Label>
            <Field
              name="subscription[0].fee"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter Fee Amount"
            />
            {errors.subscription?.[0]?.fee && touched.subscription?.[0]?.fee ? (
              <div className="text-danger">{errors.subscription[0].fee}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Address
            </BootstrapForm.Label>
            <Field
              name="address"
              as="textarea"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your address"
            />
            {errors.address && touched.address ? (
              <div className="text-danger">{errors.address}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Goal
            </BootstrapForm.Label>
            <Select
              options={goalOptions}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder="Select Goal"
              onChange={(option) => setFieldValue("goal", option.value)}
              styles={customStyles}
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12 border-bottom pb-4">
            <BootstrapForm.Label className="label_text">
              Member ID
            </BootstrapForm.Label>
            <div className="d-flex">
              <Field
                name="memberID"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter Member ID or Generate"
                disabled
              />
              <Button
                variant="outline"
                onClick={() => setFieldValue("memberID", generateMemberID())}
                className="ms-2 button_design outline-none"
              >
                Generate
              </Button>
            </div>
            {errors.memberID && touched.memberID ? (
              <div className="text-danger">{errors.memberID}</div>
            ) : null}
          </BootstrapForm.Group>
          <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
            <Button
              className="button_design w-50"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              className="pri_bg button_design w-50"
              variant="secondary"
              type="submit"
            >
              Add Student
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomForm;
