import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bg_main from "../../asset/image/Frame 1.svg";
import export_qr from "../../asset/image/export_qr.png";
import analytic_dash from "../../asset/image/analytic_dash.png";
import TestimonialSection from "../../components/landingPage/testimonialComponent";
import WhyChooseUs from "../../components/landingPage/whyChooseUs";
import FAQSection from "../../components/landingPage/faqComponent";
import FooterLanding from "../../components/landingPage/footer";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState("home");
  const navigate = useNavigate();

  const handleNavigateJoin = () => {
    navigate("/login");
  };

  const featuresData = [
    {
      key: "home",
      title: "Easy QR Access",
      description:
        "Students can join the library quickly using library QR code. Admins get instant requests and can easily approve new students.",
      image: export_qr,
    },
    {
      key: "profile",
      title: "Analytical Dashboard",
      description:
        "Admins can see important details, like how many students are active and when subscriptions will end, all in one simple view.",
      image: analytic_dash,
    },
  ];

  return (
    <div className="landing-page landing-page-css">
      <div className="main-section-landing position-relative container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="title animate__animated animate__fadeInDown head-text-landing mb-2">
            Simplifying Your <span className="dynamic-library">Library</span>{" "}
            Management
          </h1>
          <p className="para animate__animated animate__fadeInUp para-head-text-landing">
            One-stop digital solution for your Library
          </p>
          <button
            className="get-started-btn animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
            onClick={handleNavigateJoin}
          >
            Get Started
          </button>
        </div>
        <div className="d-flex align-items-center">
          <img
            src={bg_main}
            className="img-landing m-auto"
            alt="Library Management Banner"
          />
        </div>
      </div>

      <div className="tabs-with-feature-container my-5">
        <div className="d-flex flex-column-reverse flex-md-row gap-4 align-items-center col-md-10 m-auto container-div-color px-md-5">
          <div
            className="nav flex-column nav-pills px-0"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {featuresData.map((feature, index) => (
              <div
                key={index}
                className={`p-4 ${
                  activeTab === feature.key ? "active-container" : ""
                }`}
              >
                <h3 className="feature-heading-landing text-white">
                  {feature.title}
                </h3>
                <p className="feature-para-landing text-white mb-0">
                  {feature.description}
                </p>
                {activeTab !== feature.key && (
                  <button
                    className="active-btn-landing active mt-2"
                    id={`v-pills-${feature.key}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#v-pills-${feature.key}`}
                    type="button"
                    aria-controls={`v-pills-${feature.key}`}
                    aria-selected={activeTab === feature.key}
                    onClick={() => setActiveTab(feature.key)}
                  >
                    <span className="me-1">View feature</span>
                    <i className="bi bi-arrow-right"></i>
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="tab-content p-2" id="v-pills-tabContent">
            {featuresData.map((feature, index) => (
              <div
                key={index}
                className={`tab-pane fade ${
                  activeTab === feature.key ? "show active" : ""
                }`}
                id={`v-pills-${feature.key}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${feature.key}-tab`}
              >
                <img
                  className="w-100 image-feature-landing"
                  src={feature.image}
                  alt={feature.title}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <WhyChooseUs />
      <TestimonialSection />
      <div className="bg-white py-5 faq-container border-top">
        <FAQSection />
      </div>
      <FooterLanding handleNavigateJoin={handleNavigateJoin} />
    </div>
  );
};

export default LandingPage;
