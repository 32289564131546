import React from "react";
import PropTypes from "prop-types";

const testimonials = [
  {
    author: {
      fullName: "Dr. Arun Kumar",
      picture:
        "https://static.wixstatic.com/media/357548_8d79e99c90bd42bfb7b6daa466df96f3~mv2.png/v1/fill/w_336,h_414,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/IMG-7581-removebg-preview.png",
      designation: "Prof. at IIT",
    },
    description:
      "This is a game-changer for library owners. It addresses real challenges and supports a seamless experience for both owners and students.",
  },
  {
    author: {
      fullName: "Shivam Yadav",
      picture:
        "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
      designation: "Library Owner",
    },
    description:
      "The QR code system makes it easy for students to join. It’s seamless and saves me a lot of time.  The dashboard gives me all the information I need at a glance.",
  },
  {
    author: {
      fullName: "Vinod Kumar",
      picture: "https://cdn-icons-png.flaticon.com/512/194/194935.png",
      designation: "Library Owner",
    },
    description:
      "Managing my library has never been easier. The dashboard gives me all the information I need at a glance. Managing my library has never been easier.",
  },
];

const TestimonialItem = ({ testimonial }) => (
  <div className="testimonial-item p-4 border rounded shadow-sm animate__animated animate__fadeIn">
    <p className="text-muted mb-3">{testimonial.description}</p>
    <div className="d-flex align-items-center">
      <img
        src={testimonial.author.picture}
        alt={testimonial.author.fullName}
        className="rounded-circle border"
        width="50"
        height="50"
      />
      <div className="ms-3">
        <h5 className="mb-0">{testimonial.author.fullName}</h5>
        <p className="mb-0 text-muted ">{testimonial.author.designation}</p>
      </div>
    </div>
  </div>
);

TestimonialItem.propTypes = {
  testimonial: PropTypes.object.isRequired,
};

const TestimonialSection = () => (
  <section className="testimonial-section py-5 bg-light">
    <div className="container text-center">
      <h2 className="mb-4 animate__animated animate__fadeIn">
        Community Reviews
      </h2>
      <p className="mb-5 text-muted">
        Discover the best solutions for your library needs!
      </p>
      <div className="row">
        {testimonials.map((testimonial, i) => (
          <div className="col-md-6 col-lg-4 mb-3" key={i}>
            <TestimonialItem testimonial={testimonial} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default TestimonialSection;
