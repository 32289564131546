import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PhoneNumberInputComponent from "../../components/login/numberInputComponent";
import OtpVerificationComponent from "../../components/login/otpVerificationComponent";
import { sendOtp, verifyOtp } from "../../action/login/loginAction";

function LoginScreen({ loading, otpSent, user, error }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendOtp = () => {
    dispatch(sendOtp("+" + phoneNumber));
  };

  const handleVerifyOtp = () => {
    dispatch(verifyOtp(otp));
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="login-container">
      <div className="login-image-section">
        <div className="login-text-overlay">
          <h1 className="  heading-one">
            Making the <br /> "Progress Better"
          </h1>
        </div>
      </div>
      <div className="login-content-section">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container" className="d-none"></div>
        <div className="w-100 h-100">
          {otpSent ? (
            <OtpVerificationComponent
              otp={otp}
              setOtp={setOtp}
              onVerifyOtp={handleVerifyOtp}
              loading={loading}
              error={error}
              handleSendOtp={handleSendOtp}
            />
          ) : (
            <PhoneNumberInputComponent
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              onSendOtp={handleSendOtp}
              loading={loading}
              error={error}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
