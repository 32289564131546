import React, { useState } from "react";
import TableDurationComponent from "./tableDurationComponent";
import { getUpcomingExpireData } from "../../util/utilsFunction";

const TableSubscriptionComponent = ({ student_details, newOption }) => {
  const [activeTab, setActiveTab] = useState("home");

  const upcomingIn3Days = getUpcomingExpireData(3, student_details);
  const upcomingIn7Days = getUpcomingExpireData(7, student_details);

  return (
    <div className="text_card_container_dash table_dashboard table_dashboard">
      <div className="d-flex align-items-center justify-content-between mb-3 pb-1">
        <p className="cart_harding_text">Upcoming subscription end</p>
      </div>
      <ul
        className="nav nav-pills mb-3 background_ul p-2 gap-2"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link text_black button_design_withoutWeight ${
              activeTab === "home" ? "  bg-white  " : "pri_text"
            }`}
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected={activeTab === "home"}
            onClick={() => setActiveTab("home")}
          >
            3 days left
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link text_black button_design_withoutWeight ${
              activeTab === "profile" ? " bg-white  " : "pri_text"
            }`}
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected={activeTab === "profile"}
            onClick={() => setActiveTab("profile")}
          >
            7 days left
          </button>
        </li>
      </ul>
      <div className="tab-content " id="pills-tabContent">
        <div
          className={`tab-pane fade ${activeTab === "home" && "show active"}`}
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex="0"
        >
          <TableDurationComponent
            data={upcomingIn3Days}
            modalTitle="Students list upcoming 3 days"
            newOption={newOption}
          />
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === "profile" && "show active"
          }`}
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex="0"
        >
          <TableDurationComponent
            data={upcomingIn7Days}
            modalTitle="Students list upcoming 7 days"
            newOption={newOption}
          />
        </div>
      </div>
    </div>
  );
};

export default TableSubscriptionComponent;
