import React from "react";
import { useParams } from "react-router-dom";
import imageSubmitted from "../asset/image/formsubmit.svg";

export const StudentFormSubmit = () => {
  const { library_name, data_name } = useParams();

  const decodedLibraryName = decodeURIComponent(library_name);
  const decodedDataName = decodeURIComponent(data_name);

  return (
    <div className="container text-center mt-5 ">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10 col-12">
          <div className="confirmation-card p-4   ">
            <img
              src={imageSubmitted}
              alt="Form submitted"
              className="img-fluid mb-4 col-md-6"
            />
            <h3 className="mb-3">Hi, {decodedDataName}!</h3>
            <p className="mb-4">
              Thanks for submitting your request for{" "}
              <strong>{decodedLibraryName}</strong>. Please contact the library
              owner for approval.
            </p>
            <a
              href="/"
              className="button_design pri_text text-decoration-none mt-2 d-flex align-items-center justify-content-center"
            >
              <span className="material-symbols-outlined icon_dimention">
                arrow_left_alt
              </span>
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
