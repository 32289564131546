import * as XLSX from "xlsx";

// Helper function to format dates
const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const options = { day: "numeric", month: "short", year: "2-digit" };

  // Format dates and join them with a hyphen
  const formattedStart = start
    .toLocaleDateString("en-GB", options)
    .replace(",", "");
  const formattedEnd = end
    .toLocaleDateString("en-GB", options)
    .replace(",", "");
  return `${formattedStart} - ${formattedEnd}`;
};

// Helper function to generate Excel and apply formatting
export const exportToExcel = (data) => {
  // Prepare the rows for export
  const exportData = data.map((member) => {
    const sortedSubscriptions = member.subscription;

    // Create a single row for the member
    const row = {
      Name: member.name,
      "Member ID": member.memberID,
      Mobile: member.mobile,
      Email: member.email,
      Address: member.address,
      Goal: member.goal,
      Gender: member.gender,
    };

    // Map subscriptions into columns (up to 5 subscriptions)
    sortedSubscriptions.forEach((subscription, index) => {
      if (index < 5) {
        row[`S-${index + 1} Period`] = formatDateRange(
          subscription.startDate,
          subscription.endDate
        );
        row[`S-${index + 1} Seat & Shift`] = `${
          subscription.seatNo
        } (${subscription.shift.join(", ")})`;
        row[`S-${index + 1} Fee`] = subscription.fee;
      }
    });

    return row;
  });

  // Create a new workbook and worksheet
  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Subscriptions");

  // Adjust column widths for better readability
  const columnWidths = [
    { wch: 15 }, // Name
    { wch: 20 }, // Member ID
    { wch: 15 }, // Mobile
    { wch: 25 }, // Email
    { wch: 30 }, // Address
    { wch: 10 }, // Goal
    { wch: 10 }, // Gender
    { wch: 20 }, // S-1 Period
    { wch: 25 }, // S-1 Seat & Shift
    { wch: 10 }, // S-1 Fee
    { wch: 20 }, // S-2 Period (repeat for up to 5 subscriptions)
    { wch: 25 },
    { wch: 10 },
    { wch: 20 },
    { wch: 25 },
    { wch: 10 },
    { wch: 20 },
    { wch: 25 },
    { wch: 10 },
  ];
  ws["!cols"] = columnWidths;

  // Apply alternating row colors (white and light gray)
  const range = XLSX.utils.decode_range(ws["!ref"]);
  for (let R = range.s.r; R <= range.e.r; R++) {
    const rowColor = R % 2 === 0 ? "FFFFFF" : "D3D3D3"; // White and Light Gray
    for (let C = range.s.c; C <= range.e.c; C++) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
      if (!ws[cellRef]) continue; // Skip empty cells
      if (!ws[cellRef].s) ws[cellRef].s = {};
      ws[cellRef].s.fill = { fgColor: { rgb: rowColor } };
    }
  }

  // Generate and download the Excel file
  XLSX.writeFile(wb, "Subscriptions.xlsx");
};
