import OtpInput from "otp-input-react";
import { useState, useEffect } from "react";

const OtpVerificationComponent = ({
  otp,
  setOtp,
  onVerifyOtp,
  loading,
  otpError,
  handleSendOtp,
}) => {
  const [resend, setSend] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setSend(true);
    }
  }, [timer]);

  const handleResendOtp = () => {
    handleSendOtp();
    setTimer(30);
    setSend(false);
  };

  return (
    <div className="opt-container m-auto d-flex flex-column justify-content-center h-100 gap-md-4 gap-3">
      <h3 className="heading_three mb-0">Verify OTP</h3>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="otp" className="label_text">
            Enter your OTP
          </label>
          <button
            className="bg-white border-0 p-0 label_text pri_text"
            onClick={handleResendOtp}
            disabled={loading || !resend}
          >
            {timer > 0 ? <span>{timer} sec</span> : <span>Resend OTP</span>}
          </button>
        </div>
        <OtpInput
          value={otp}
          onChange={setOtp}
          OTPLength={6}
          otpType="number"
          disabled={false}
          autoFocus
          className="opt-container"
        />
      </div>
      <button
        onClick={onVerifyOtp}
        className="btn border-0 outline-0 pri_bg text-white button_design sent_otp_btn"
        disabled={loading}
      >
        {loading ? (
          <div className="ms-3">
            <div
              className="spinner-border text-light spinner_class"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <span>Verify OTP</span>
        )}
      </button>
      {otpError && <p className="text-red-500 text-center">{otpError}</p>}
    </div>
  );
};

export default OtpVerificationComponent;
