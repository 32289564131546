import React, { useState, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { logoutAction } from "../action/login/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { getBaseURL } from "../util/utilsFunction";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

function MobSideBar({ handleClose, show, getActiveClass, navigate }) {
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const user_uid = sessionStorage.getItem("user_uid");
  const library_name = sessionStorage.getItem("library_name");
  const dispatch = useDispatch();
  const [showQrModal, setShowQrModal] = useState(false);
  const domainUrl = getBaseURL(window.location);
  const baseUrl = `${domainUrl}student-form/${library_name}/${user_uid}`;

  const qrRef = useRef(null);

  const handleLogout = () => {
    dispatch(logoutAction(() => navigate("/login")));
    sessionStorage.clear();
  };

  const handleQrModalOpen = () => setShowQrModal(true);
  const handleQrModalClose = () => setShowQrModal(false);

  const handleQrGenerate = () => {
    handleQrModalOpen();
  };

  const handleDownloadQR = () => {
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "librify-qr-code.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("Failed to download QR code", err);
        });
    }
  };

  const handleNavigateWeb = () => {
    if (user_uid) {
      navigate(`/student-form/${library_name}/${user_uid}`);
    }
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleClose(!show);
  };
  return (
    <>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Choose your section</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column gap-2 border-bottom pb-3">
            <button
              className={` border-0 outline-none button_design_withoutWeight btn_sidebar ${getActiveClass(
                "/dashboard"
              )} d-flex gap-2 align-items-center `}
              onClick={() => handleNavigation("/dashboard")}
            >
              <span className="material-symbols-outlined icon_dimention">
                dashboard
              </span>
              <span>Dashboard</span>
            </button>
            <button
              className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
                "/students"
              )} d-flex gap-2 align-items-center`}
              onClick={() => handleNavigation("/students")}
            >
              <span className="material-symbols-outlined icon_dimention">
                manage_accounts
              </span>
              <span>Students</span>
            </button>
            <button
              className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
                "/profile"
              )} d-flex gap-2 align-items-center`}
              onClick={() => handleNavigation("/profile")}
            >
              <span className="material-symbols-outlined icon_dimention">
                account_circle
              </span>
              <span>Profile</span>
            </button>
          </div>
          <div className="d-flex flex-column gap-2 pt-3 pb-3">
            <button
              disabled={!newUserDetail.data.approval}
              className={` border-0 outline-none button_design_withoutWeight btn_sidebar d-flex gap-2 align-items-center ${
                !newUserDetail.data.approval && "disable_button"
              }`}
              onClick={handleQrGenerate}
            >
              <span className="material-symbols-outlined icon_dimension">
                qr_code
              </span>
              <span>Generate QR</span>
            </button>
            <button
              className={` border-0 outline-none button_design_withoutWeight btn_sidebar d-flex gap-2 align-items-center `}
              onClick={handleLogout}
            >
              <span className="material-symbols-outlined icon_dimension">
                move_item
              </span>
              <span>Logout</span>
            </button>
          </div>
          <Modal
            show={showQrModal}
            onHide={handleQrModalClose}
            backdrop="static"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title className="heading_four text_gray">
                Download your QR code
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div ref={qrRef} className="qr-code-container">
                <div className="qr-code-header">
                  <h2 className="qr-library-name">Librify</h2>
                </div>
                <div className="qr-code-content">
                  <QRCode value={baseUrl} />
                </div>
                <div className="qr-code-footer">
                  <p>Scan to Join</p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
                <Button
                  className="button_design w-50 d-flex align-items-center justify-content-center shadow-none"
                  variant="outline"
                  onClick={handleNavigateWeb}
                >
                  <span> Website</span>
                  <span className="material-symbols-outlined icon_dimention">
                    north_east
                  </span>
                </Button>

                <Button
                  className="pri_bg button_design w-50 shadow-none"
                  variant="secondary"
                  type="submit"
                  onClick={handleDownloadQR}
                >
                  Download QR
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobSideBar;
